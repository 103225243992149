<template>
      <div class="team-management">
        <div class="people-header">
          <div class="columns">
            <div class="column is-5">
              <input-vue
                :required="false"
                :min="1"
                type="text"
                :max="150"
                placeholder="Search by name or email"
                @onChangeName="onChangeSearch"
                label="" />
            </div>
            <div v-if="allowedRoles.length>1 && canManageUsers" class="column padding-left is-7">
              <div class="teams inline-fields">
              <input-vue
                :required="true"
                :min="1"
                :value="email"
                :showLable="false"
                type="text"
                :max="150"
                :submitted="submitted"
                placeholder="Invite by email (multiple lines and CSV accepted)"
                @onEnter="inviteUser"
                @onChangeName="onChangeEmail"
                label="Email" />
                <div
                  v-if="submitted && !inValid && isEmailInvalid"
                  class="required-error">
                  Please enter valid email id(s).
                </div>
                <dropdown-wrap-vue @onClickOutside="onClickOutside">
                <div class="button-wrap">
                <b-button @click="openRoles" class="roles">
                  <div class="roles-align">
                  <p v-if="selectedRole.role">{{selectedRole.role}}</p>
                  <p v-else>Select a Role</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
                  </div>
                </b-button>
                <div v-if="open" class="button-container">
                  <ul>
                    <li @click="updateLocalRole(role)" v-for="(role,index) of allowedRoles" :key="index">{{role.role}}</li>
                  </ul>
                </div>
                </div>
                </dropdown-wrap-vue>
                <b-button @click="inviteUser" class="invite"><p>Invite</p></b-button>
              </div>
            </div>
          </div>
        </div>
        <teams-table-vue @onRemove="onRemoveFun" @sortBy="sortBy" :sort="sort" />
      </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'
import { metaTitle } from '../../../../constant/data'
import InputVue from '../../../Admin/components/Input.vue'
import TeamsTableVue from './TeamsTable.vue'
import { debounce } from '../../../../util/util'

export default {
  name: 'People',
  metaInfo () {
    return {
      title: metaTitle.people
    }
  },
  components: {
    InputVue,
    TeamsTableVue,
    DropdownWrapVue
  },
  data () {
    return {
      email: null,
      submitted: false,
      isEmailInvalid: false,
      inValid: false,
      search: null,
      open: false,
      selectedRole: {},
      sort: { by: 'asc', field: 'role' }
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('role', ['allowedRoles']),
    canManageUsers () {
      let permissions = this.userDetails.permissions
      let settingsPermission = permissions.find(permit => permit.path.includes('/settings'))
      return settingsPermission ? !!settingsPermission.write : false
    }

  },
  mounted () {
    this.getAllowedRoles()
    this.getUsers()
  },
  methods: {
    ...mapActions('invitation', ['sendInvitation', 'getInvitedUsers']),
    ...mapActions('role', ['getAllowedRoles']),
    debounceInput: debounce(function (text) {
      this.search = text
      if (text != null) {
        this.getUsers()
      }
    }, 500),
    onChangeSearch (text) {
      this.debounceInput(text)
    },
    onChangeEmail (text, isValid) {
      this.email = text
      this.inValid = isValid
      this.checkEmail()
    },
    checkEmail () {
      if (!(this.email == null || this.email === '')) {
        const emailIds = this.email.split(',').map((email) => email.trim())
        for (const email of emailIds) {
          // eslint-disable-next-line prefer-regex-literals
          if (!RegExp(
            /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
          ).test(email) && email !== '') {
            this.isEmailInvalid = true
            return false
          }
        }
        this.isEmailInvalid = false
        return true
      } else {
        this.isEmailInvalid = true
      }
    },
    updateLocalRole (role) {
      this.selectedRole = role
      this.open = !this.open
    },
    async inviteUser () {
      this.submitted = true
      if (this.checkEmail()) {
        const res = await this.sendInvitation({
          emails: this.email.split(',').map((email) => email.trim()).filter((val) => val !== ''),
          role: this.selectedRole.r_id
        })
        if (res) {
          this.submitted = false
          this.email = null
          this.getUsers()
        } else {
          this.submitted = false
        }
      }
    },
    sortBy (object) {
      this.sort = object
      this.getUsers()
    },
    onClickOutside () {
      this.open = false
    },
    openRoles () {
      this.open = !this.open
    },
    getUsers () {
      this.getInvitedUsers({ search: this.search, sort: this.sort })
    },
    onRemoveFun () {
      this.getUsers()
    }
  }
}
</script>

<style lang="scss" scoped>

.team-management {
  .people-header {
    .columns {
      .column {
        position: relative;
        .required-error {
          color: red;
          position: absolute;
          font-size: 12px;
          bottom: 0px;
        }
        padding: 20px 0px;
          button {
            height: 36px;
            padding:0px;
            color: white;
            border: none;
        }
        .inline-fields {
          display: flex;
          .form-group {
            width: 100%;
          }
          .button-wrap {
            position: relative;
          .roles {
            background: var(--background-color);
            border: var(--tertiary-border-color);
            border-right-width: 0px;
            border-left-width: 0px;
            border-radius:0px;
            .roles-align {
              width: 153px;
              display: flex;
              padding: 0px 10px;
              align-items: center;
              justify-content: center;
              p {
                font-size: 14px;
                padding-right: 10px;
                font-family: Quicksand;
                color: var(--secondary-text-color);
                text-transform: capitalize;
              }
            }
          }
          .button-container {
            position: absolute;
            background: var(--background-color);
            box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
            border-radius: 4px;
            left: 0px;
            width: 100%;
            z-index: 100;
            max-height: 190px;
            overflow-y: auto;
            ul {
              li {
                font-size: 14px;
                font-family: Quicksand;
                color: var(--secondary-text-color);
                border: var(--tertiary-border-color);
                padding: 5px;
                text-align: center;
                border-top: none;
                cursor: pointer;
                text-transform: capitalize;
              }
            }
          }
          }
          .invite {
            background: #2780eb;
            border-radius:0px;
            border: 1px solid #2780eb;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            p {
              padding: 0px 10px;
              width: 153px;
              font-size: 14px;
              font-family: Quicksand;
            }
          }
        }
      }
      .padding-left {
        padding-left: 12px;
      }
    }
  }
}
</style>
