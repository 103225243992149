<template>
  <div class="setting">
    <dropdown-wrap-vue @onClickOutside="onClickOutside">
      <div class="setting-wrap">
        <b-button class="setting-main" @click="openSetting">
          <b-icon icon="dots-vertical" size="is-small"> </b-icon>
        </b-button>
        <div v-if="open" class="setting-options">
          <ul class="setting-list">
            <li @click="onClick('re-invite')" v-if="user.status == 2 && showReinviteAction">
              Re-invite
            </li>
            <li v-if="showRemoveAction" @click="onClick('remove')">Remove</li>
          </ul>
        </div>
      </div>
    </dropdown-wrap-vue>
    <modal-vue @onChange="onChangeModal" :modal="modal">
      <div class="user-delete-modal">
        <div class="user-delete-inside">
          <p class="modal-header">Delete User</p>
          <div class="modal-data">
          <p>
            Are you sure you want to delete <b>{{user.firstName}} {{user.lastName}} ({{user.email}})</b>?
          </p>
          <div class="actions">
            <b-button @click="onChangeModal(false)">Cancel</b-button>
            <b-button @click.once="removeUserHandler">Delete</b-button>
          </div>
          </div>
        </div>
      </div>
    </modal-vue>
  </div>
</template>

<script>
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'
import ModalVue from '../../../Admin/components/Modal.vue'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'Setting',
  components: {
    DropdownWrapVue,
    ModalVue
  },
  props: ['user', 'onRemove'],
  data () {
    return {
      open: false,
      modal: false
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('role', ['allowedRoles']),
    showRemoveAction () {
      let hasAllowedRoles = [...this.allowedRoles.filter(data => Number(data.r_id) === Number(this.user.role))].length
      let permissions = this.userDetails.permissions
      let settingsPermission = permissions.find(permit => permit.path.includes('/settings'))
      return !!hasAllowedRoles && (!!settingsPermission.delete)
    },
    showReinviteAction () {
      let hasAllowedRoles = [...this.allowedRoles.filter(data => Number(data.r_id) === Number(this.user.role))].length
      let permissions = this.userDetails.permissions
      let settingsPermission = permissions.find(permit => permit.path.includes('/settings'))
      return !!hasAllowedRoles && (!!settingsPermission.update)
    },
  },
  
  methods: {
    ...mapActions('invitation', ['reInviteUser', 'removeUser']),
    
    onClickOutside () {
      this.open = false
    },
    onChangeModal (value) {
      this.modal = value
    },
    openSetting () {
      this.open = !this.open
    },
    async onClick (type) {
      switch (type) {
        case 're-invite':
          this.reInviteUser(this.user.userId)
          break
        case 'remove':
          // this.removeUser()
          this.modal = true
          break
      }
      this.open = !this.open
    },
    async removeUserHandler () {
      const res = await this.removeUser(this.user.userId)
      if (res) {
        this.modal = false
        this.$emit('onRemove')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.setting {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  .user-delete-modal {
    display: flex;
    justify-content: center;
    .user-delete-inside {
      display: flex;
      flex-direction: column;
      background: var(--dropdown-backgroud);
      border-radius: 4px;
      width: 324px;
      height: 184px;
      padding: 25px;
      .modal-data {
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    p {
      font-size: 14px;
    }
      }
      .modal-header {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
      }

    }
    .actions {
      padding-top: 20px;
      text-align: end;
      .button {
        height: 32px;
        border: none;
        font-size: 14px;
        width: 75px;
      }
      .button:nth-child(1) {
        margin-left: 5px;
        background-color: transparent;
        color: var(--secondary-text-color);
        border: 1px solid var(--primary-border-color);
        &:hover {
          border: 2px solid var(--primary-border-color);
          transition: border 0.3s ease-out;
        }
        &:active {
          background-color: #7F8FA466;
          transition: background-color 0.3s ease-out;
        }
      }
      .button:nth-child(2) {
        margin-left: 5px;
        background-color: #FF0000;
        color: white;
        &:hover {
          background-color: #F64A4A;
          transition: background-color 0.3s ease-out;
        }
        &:active {
          background-color: #EE1111;
          transition: background-color 0.3s ease-out;
        }
      }
    }
  }
  .setting-wrap {
    width: 20px;
    text-align: center;
    position: relative;
    .setting-main {
      background: transparent;
      padding: 0px;
      width: 100%;
      border: none;
      height: 100%;
    }
    .setting-options {
      position: absolute;
      left: 0px;
      z-index: 100;
      width: 70px;
      background: var(--background-color);
      border-radius: 4px;
      .setting-list {
        font-size: 12px;
        // display: flex;
        padding: 5px;
        li {
          text-align: left;
          padding: 5px;
          cursor: pointer;
        }
      }
    }
    .icon {
      color: #4d586b;
      transform: rotate(90deg);
    }
  }
}
</style>
