<template>
  <div class="invitation-row">
    <td>
      <div class="common-height">
        <div v-if="localUser" class="user-detail">
          <div class="teams-name">
            <div class="teams-image-block">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" v-if="!user.avatar" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="none" d="M0 0h26v26H0z"/><path fill="#d6d6d6" d="M12.999 2.284a5.613 5.613 0 105.615 5.613 5.613 5.613 0 00-5.615-5.613zm0 9.185a3.572 3.572 0 113.572-3.572 3.572 3.572 0 01-3.572 3.572zm8.726 6.634a15.972 15.972 0 00-8.726-2.551 16.38 16.38 0 00-8.726 2.5l-.459.306v4.337a1.021 1.021 0 001.021 1.021h16.329a1.021 1.021 0 001.021-1.021v-4.337zm-1.582 3.572H5.855v-2.191a14.492 14.492 0 017.144-1.888 14.033 14.033 0 017.144 1.939z"/></svg>
              <img v-else :src="user.avatar" />
            </div>
            <div v-if="user.firstName" class="space-inside">
              <p>{{ `${user.firstName} ${user.lastName}` }}</p>
            </div>
          </div>
          <div class="align-buttons">
            <div v-if="user.status == 2" class="status-button">
              <b-button class="pending"><p>{{ 'Pending' }}</p></b-button>
            </div>
            <div v-if="localUser.is_super_admin" class="status-button">
              <b-button class="admin"><p>{{ localUser.roleName }}</p></b-button>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td>
      <div class="common-height">{{ user.email }}</div>
    </td>
    <td>
      <div class="common-height">
        <role-vue :user="user" @onChange="onChange" />
      </div>
    </td>
    <td>
      <div class="common-height">
        <div class="last-active">{{ getDate(user.lastLogin) }}</div>
      </div>
    </td>
    <td v-if="(showSettingColumns)">
      <div class="common-height" v-if="showSettingActions">
        <settings-vue @onRemove="onRemoveFun" :user="user" v-if="(userDetails.userId != user.userId)" />
      </div>
    </td>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import SettingsVue from './Setting.vue'
import RoleVue from './Role.vue'

export default {
  name: 'InvitationRow',
  components: {
    SettingsVue,
    RoleVue
  },
  props: ['user', 'onRemove'],
  data () {
    return {
      open: false,
      localUser: null
    }
  },
  computed: {
    ...mapState('invitation', ['userList']),
    ...mapState('user', ['userDetails']),
    ...mapState('role', ['allowedRoles']),
    showSettingActions () {
      let hasAllowedRoles = [...this.allowedRoles.filter(data => Number(data.r_id) === Number(this.localUser.role))].length
      let permissions = this.userDetails.permissions
      let settingsPermission = permissions.find(permit => permit.path.includes('/settings'))
      return hasAllowedRoles && (!!settingsPermission.update || !!settingsPermission.delete)
    },
    showSettingColumns () {
      let permissions = this.userDetails.permissions
      let settingsPermission = permissions.find(permit => permit.path.includes('/settings'))
      return settingsPermission ? (!!settingsPermission.update && this.user.status == 2) || !!settingsPermission.delete : false
    }

  },
  watch: {
    'user' () {
      this.localUser = this.user
    }
  },
  mounted () {
    this.localUser = this.user
  },
  methods: {
    ...mapActions('invitation', ['getInvitedUsers']),
    getDate (date) {
      if (date) {
        return moment(date).format('MM-DD-YYYY')
      }
      return '-'
    },
    onChange (role) {
      this.localUser.role = role
    },
    onRemoveFun () {
      this.$emit('onRemove')
    }
  }
}
</script>

<style lang="scss" scoped>
.invitation-row {
    width: 100%;
    display: contents;
      td {
          text-align: left;
      padding: 8px;
      font-family: 'ROBOTO';
      color: var(--secondary-text-color);
        .common-height {
          height: 50px;
          display: flex;
          align-items: center;
        }

        .last-active {
          // text-align: center;
          width: 100%;
        }
        .role-types {
          text-align: center;
          width: 100%;
        }
        .user-detail {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .align-buttons {
            display: flex;
          }
          .teams-name {
            display: flex;
            align-items: center;
            .teams-image-block {
              width: 36px;
              height: 36px;
              display: flex;
              background-color: var(--user-profile-image-background-color);
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              svg path:nth-child(2) {
                fill: var(--user-profile-default-image-color);
              }
              img {
                border-radius: 50%;
              }
            }
            .space-inside {
              padding-left: 10px;
            }
          }
          .status-button {
            button {
              height: 24px;
              padding: 5px;
              background: transparent;
              border-radius: 4px;
              p {
                font-size: 11px;
                font-family: Quicksand;
              }
            }
            .admin {
              margin-left: 5px;
              color: #eece17;
              border: 1px solid #eece17;
              text-transform: capitalize;
            }
            .pending {
              color: #9089fa;
              border: 1px solid #9089fa;
            }
          }
        }
      }
}
</style>
