<template>
  <div class="role">
    <dropdown-wrap-vue v-if="isEditable" @onClickOutside="onClickOutside">
      <div v-if="user.userId!=userDetails.userId" class="role-wrap">
        <b-button class="role-main" @click="openSetting">
          <div class="role-container">
              <span></span>
              <p class="role-label">{{localRole.role}}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
          </div>
        </b-button>
        <div v-if="open" class="role-options">
          <ul class="role-list">
            <li @click="changeRole(role)" v-for="(role,index) of allowedRoles" :key="index">{{role.role}}</li>
          </ul>
        </div>
      </div>
      <div v-else>
          <div class="static-role">{{localRole.role}}</div>
      </div>
    </dropdown-wrap-vue>
    <p class="role-label" v-else>{{user.roleName}}</p>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DropdownWrapVue from '../../../../components/Common/DropdownWrap.vue'

export default {
  name: 'Role',
  components: {
    DropdownWrapVue
  },
  props: ['user', 'onChange'],
  data () {
    return {
      open: false,
      localRole: {}
    }
  },
  computed: {
    ...mapState('user', ['userDetails']),
    ...mapState('role', ['allowedRoles']),
    canManageUsers () {
      let permissions = this.userDetails.permissions
      let settingsPermission = permissions.find(permit => permit.path.includes('/settings'))
      return settingsPermission ? !!settingsPermission.update : false
    },
    isEditable () {
      return (
        this.canManageUsers && // current user have access to user-management feature granted to its role( Roles & Permissions )
        this.userDetails.userId != this.user.userId && // user in current row should not be the logged in user
        this.allowedRoles.length > 1 && // Logged in user should have access to other roles to change the current role
        this.localRole.role // local role info should be present
      )
    }
  },
  watch: {
    'user.role' () {
      this.getRole()
    },
    allowedRoles () {
      this.getRole()
    }
  },
  mounted () {
    this.getRole()
  },
  methods: {
    ...mapActions('invitation', ['updateUserRole']),
    onClickOutside () {
      this.open = false
    },
    openSetting () {
      this.open = !this.open
    },
    getRole () {
      const data = this.allowedRoles.filter(data => Number(data.r_id) === this.user.role)
      if (data.length) {
        this.localRole = data[0]
      }
    },
    changeRole (role) {
      if (this.localRole !== role) {
        this.localRole = role
        this.updateUserRole({ userId: this.user.userId, role: this.localRole.r_id })
        this.$emit('onChange', this.localRole.r_id)
      }
      this.open = !this.open
    }
  }
}
</script>

<style lang="scss" scoped>
 .role {
   .role-label {
     font-family: "ROBOTO";
      color: var(--secondary-text-color);
      padding: 0px 10px 0px 5px;
      font-size: 14px;
      text-transform: capitalize;
    }
     .static-role {
         font-family: "ROBOTO";
        color: var(--secondary-text-color);
        font-size: 14px;
        text-transform: capitalize;
     }
          display: flex;
          align-items: center;
          width: 100%;
          // justify-content: center;
          .role-wrap {
            width: 100%;
            text-align: center;
            position: relative;
            .role-main {
            background: transparent;
            padding: 0px;
            width: 100%;
            border: none;
            height: 100%;
            .role-container {
                display: flex;
                align-items: center;
                span {
                    width: 2px;
                    height: 17px;
                    background-color: #2780eb;
                }
            }
          }
          .role-options {
              position: absolute;
              left: 0px;
              z-index: 100;
              width: 110px;
              background: var(--background-color);
              border-radius: 4px;
              max-height: 200px;
              overflow-y: auto;
              .role-list {
                font-size: 14px;
                cursor: pointer;
                // display: flex;
                padding: 0px 5px;
                li {
                  text-align: left;
                  padding: 5px;
                  text-transform: capitalize;
                }
              }
            }
          }

        }
</style>
