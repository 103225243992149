<template>
  <div class="teams-table-container">
    <div class="teams-tabs">
      <p>Full Members ({{ userList.length }})</p>
    </div>
    <div class="teams-table">
      <table>
        <tr>
          <th @click="sortFun(header.alias)" v-for="(header, index) of headers" :key="index">
            <div class="header-block">
            <p>{{ header.name }}</p>
            <div class="sort-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                  active.by == 'asc' && active.field == header.alias ? 'active' : ''
                ]" v-bind:class="'rotate-sort'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 197.286L159.028 69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.286C1.807 200.9 0 205.184 0 210.132s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.428 12.85 5.428h255.813c4.949 0 9.233-1.811 12.848-5.428 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.232-5.427-12.846z"/></svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362" viewBox="0 0 292.362 292.362" :class="[
                  active.by == 'desc' && active.field == header.alias ? 'active' : ''
                ]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"/></svg>
            </div>
          </div>
          </th>
          <th v-if="(showSettingColumns)">settings</th>
        </tr>
        <tr v-for="(user, index) of userList" :key="index">
          <invitation-row-vue @onRemove="onRemoveFun" :user="user" />
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InvitationRowVue from './InvitationRow.vue'

export default {
  name: 'TeamsTable',
  components: {
    InvitationRowVue
  },
  props: ['sortBy', 'sort', 'onRemove'],
  data () {
    return {
      headers: [
        { name: 'Name', alias: 'name' },
        { name: 'Email', alias: 'email' },
        { name: 'Role', alias: 'role' },
        { name: 'Last Active', alias: 'last_login' }
      ],
      open: false,
      active: {
        by: null,
        field: null
      }
    }
  },
  computed: {
    ...mapState('invitation', ['userList']),
    ...mapState('user', ['userDetails']),
    showSettingColumns () {
      let permissions = this.userDetails.permissions
      let settingsPermission = permissions.find(permit => permit.path.includes('/settings'))
      return settingsPermission ? !!settingsPermission.update || !!settingsPermission.delete : false
    }

  },
  watch: {
    active () {
      this.$emit('sortBy', this.active)
    }
  },
  mounted () {
    this.active = this.sort
  },
  methods: {
    sortFun (name) {
      this.active = {
        by: (this.active.by === 'asc' && name === this.active.field) ? 'desc' : 'asc',
        field: name
      }
    },
    onRemoveFun () {
      this.$emit('onRemove')
    }
  }
}
</script>

<style lang="scss" scoped>
.teams-table-container {
  .teams-tabs {
    p {
      font-family: Quicksand;
      font-size: 14px;
      font-weight: 500;
      color: var(--secondary-text-color);
    }
  }
  .teams-table {
    margin: 10px 0px 20px;
    background: var(--primary);
    border-radius: 4px;
    padding: 10px 20px;
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    th {
      text-align: left;
      padding: 8px;
      font-family: 'ROBOTO';
      color: var(--secondary-text-color);
      font-weight: 500;
      text-transform: uppercase;
      .header-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          outline: none;
          width: 8px;
          fill: var(--secondary-text-color);
          height: 8px;
          display: flex;
        }
        .active {
          fill: rgba(128, 128, 128,0.5);
        }
        .sort-container {
          display: flex;
          flex-direction: column;
          padding: 0px 5px 0px 10px;
        }
      }
    }
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5) {
      text-align: center;
    }

    tr {
      border-bottom: 1px solid var(--voc-bar-background-color);
    }
    tr:last-child {
      border-bottom: none;
    }
  }
}
</style>
